import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import { motion } from "framer-motion"
import TransitionLink from "gatsby-plugin-transition-link"

const elementFade = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1
  }
}

const navigationPartnershipsAnimation = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      ease: "easeIn",
      duration: 1,
      delay: .4
    }
  },
}
const sequenceFade = {
  hidden: {
    opacity: 0,
    // y: "10px"
  },
  visible: i => ({
    opacity: 1,
    // y: 0,
    transition: {
      duration: .6,
      delay: i * 0.2,
      ease: "easeInOut"
    },
  })
}

const NavigationPartnerships = (props) => {

  const data = useStaticQuery(graphql`
    query NavigationPartnershipsQuery {
      allPrismicPartnerships {
        edges {
          node {
            lang
            data {
              partnership_items {
                partnership {
                  document {
                    id
                    uid
                    data {
                      title {
                        text
                      }
                      image {
                        url
                        localFile {
                          url
                          childImageSharp {
                            fluid(maxWidth: 1400, pngQuality: 80) {
                              src
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `).allPrismicPartnerships.edges.filter(edge => edge.node.lang === props.locale)[0]

  const template = props.template
  const partnershipItems = data.node.data.partnership_items

  return (    
    <motion.div
      className={`container is-fluid ${template}`}
      initial="hidden"
      animate="visible"
      variants={navigationPartnershipsAnimation}>


      <div className="partnerships-navigation columns is-tablet">
        {partnershipItems.map(({partnership}, index) => {
          return (
            <motion.div
              className="partnerships-column column"
              custom={index+1}
              key={partnership.document[0].id}
              initial="hidden"
              animate="visible"
              variants={sequenceFade}>
              <TransitionLink
                to={`/${partnership.document[0].uid}`}
                exit={{ length: 0.5 }}
              >
                <div 
                  className="partnerships-column__bg"
                  style={{ backgroundImage: `url(${partnership.document[0].data.image.localFile.childImageSharp.fluid.src})`}}>  
                </div>
              </TransitionLink>    
            </motion.div>
          )
        })}
      </div>
    </motion.div>
  )
}

export default NavigationPartnerships
