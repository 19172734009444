import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import { TransitionState } from "gatsby-plugin-transition-link"
import posed from "react-pose"
import { motion } from "framer-motion"

// import { GlobalStateContext } from "../context/GlobalContextProvider"
import SEO from '../components/Seo'
import Layout from '../components/Layout'
import NavigationPartnershipsLogos from "../components/NavigationPartnershipsLogos"
import NavigationPartnerships from '../components/NavigationPartnerships'

const PageFade = posed.div({
  hidden: { opacity: 0 },
  visible: { opacity: 1 }
})
const elementFade = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1
  }
}
const sequenceFade = {
  hidden: {
    opacity: 0,
    y: "10px"
  },
  visible: i => ({
    opacity: 1,
    y: 0,
    transition: {
      duration: .6,
      delay: i * 0.3,
      ease: "easeInOut"
    },
  })
}

const PartnershipsPage = (props) => {

  // console.log('PartnershipsPage page props: ', props)
  // console.log('props.pageContext.lang: ', props.pageContext.lang)
  const metadata = props.data.partnershipsMetadata.metadata
  const partnershipsContent = props.data.allPrismicPartnerships.nodes[0].data
  const partnershipItems = props.data.allPrismicPartnerships.nodes[0].data.partnership_items
  // console.log('partnershipsContent: ', partnershipsContent)

  return (
    <Layout props={props}>
      <TransitionState>
        {({ transitionStatus }) => (
          <PageFade
            pose={
              ["entering", "entered"].includes(transitionStatus)
                ? "visible"
                : "hidden"
            }
          >
            <SEO
              locale={props.pageContext.lang}
              title={metadata.metatitle}
              description={metadata.description}
              keywords={metadata.keywords}
              image={metadata.metadata_image.localFile.childImageSharp.fixed.src}
              pathname={props.location.pathname}
              origin={props.location.origin}
            />

            <div className="partnerships-page wrapper is-fluid">
              <div className="columns is-desktop">
                <div className="column is-8 is-8-desktop is-offset-2 is-offset-2-desktop">
                  <div className="partnerships-page__content content has-text-centered">
                    <motion.h1
                      className="is-size-2 is-size-1-fullhd"
                      custom="1"
                      initial="hidden"
                      animate="visible"
                      variants={sequenceFade}>
                        {partnershipsContent.copy.text}
                    </motion.h1>
                  </div>
                </div>
              </div>

              <div className="columns is-tablet">
                <div className="column is-10-desktop is-offset-1-desktop">
                    {/* Partnerships Logos Animation fade in */}
                    <motion.div
                      custom="2"
                      initial="hidden"
                      animate="visible"
                      variants={sequenceFade}>
                      <NavigationPartnershipsLogos data={partnershipItems}/>
                    </motion.div>
                </div>
              </div>
              
              <div className="columns is-tablet is-vcentered">
                <div className="column is-10-desktop is-offset-1-desktop">
                  <motion.div
                    custom="2"
                    initial="hidden"
                    animate="visible"
                    variants={sequenceFade}>
                      <NavigationPartnerships template="full" locale={props.pageContext.lang} />
                  </motion.div>
                </div>
              </div>

            </div>

          </PageFade>
        )}
      </TransitionState>
    </Layout>
  )
}

export const partnershipsData = graphql`
  query PartnershipsQuery($id: String! $lang: String!) {
    partnershipsMetadata: prismicPartnershipsBodyMetadata(id: {eq: $id}) {
      metadata: primary {
        metatitle
        description
        keywords
        metadata_image {
          localFile {
            childImageSharp {
              fixed {
                src
              }
            }
          }
        }
      }
    }
    allPrismicPartnerships(filter: {lang: {eq: $lang}}) {
      nodes {
        data {
          copy {
            text
          }
          partnership_items {
            partnership {
              id
              uid
              document {
                id
                uid
                data {
                  logo {
                    localFile {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default PartnershipsPage;
