import React from 'react'
import TransitionLink from "gatsby-plugin-transition-link"

const NavigationPartnershipsLogos = (props) => {

  const uid = props.uid
  const partnershipItems = props.data

  return (
    <div className={`partnerships-logos columns is-mobile`}>
      {partnershipItems.map(({partnership}, index) => {
        return (
          <div 
            className={`partnerships-logo column ${uid === partnership.uid ? 'active' : ''}`}
            key={partnership.id}>
            <TransitionLink
              to={`/${partnership.uid}`}
              exit={{ length: 0.5 }}
            >
              <img src={partnership.document[0].data.logo.localFile.url}/>
            </TransitionLink>    
          </div>
        )
      })}
    </div>
  )
}

export default NavigationPartnershipsLogos
